import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllServiceCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServiceCategory/GetAllForAdmin')
    }
    setParams(data) {
        super.setRequestParam(data)
    }
}
class GetAllServiceCategoryForAll extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`ServiceCategory/GetAll`);
    }

}
class DeleteServiceCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`ServiceCategory/Delete?id=${data.id}`);
    }
}

class UpdateServiceCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServiceCategory/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateServiceCategory extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServiceCategory/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

export {
    GetAllServiceCategory,
    GetAllServiceCategoryForAll,
    DeleteServiceCategory,
    UpdateServiceCategory,
    CreateServiceCategory
};
